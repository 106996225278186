'use client';
import { ReactNode, useState } from 'react';
import useThemeToggle from '../hooks/useThemeToggle';
import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect';

type Props = {
  children?: ReactNode;
};

const AppLayoutWrapper = (props: Props) => {
  const { theme } = useThemeToggle();
  const [loading, setLoading] = useState(true);

  const { children } = props;
  useIsomorphicLayoutEffect(() => {
    if (theme && !document.documentElement.classList.contains(theme)) {
      document.documentElement.classList.add(theme);
    }
    setLoading(!theme);
    return () => {};
  }, [theme]);
  // show a loading indicator while theme is getting loaded
  return <>{loading ? null : children}</>;
};

export default AppLayoutWrapper;
