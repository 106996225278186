import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
};

const SectionWrapper = (props: Props) => {
  const { children, className } = props;
  return <div className={`py-5 overflow-hidden ${className}`}>{children}</div>;
};

export default SectionWrapper;
